@import "../../styles/variables.module";
.topNavbar {
  position: relative;
  overflow: hidden;
  header {
    padding: 80px 0;
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      padding: 50px 0;
    }
    @media screen and (max-width: 575px) {
      padding: 30px 0;
    }
    .download_hero {
      @media screen and (max-width: 767px) {
        margin-top: 20px;
        order: 2;
        text-align: center;
      }
      .download {
        & ~ .d-flex,
        ul {
          @media screen and (max-width: 991px) {
            justify-content: center;
          }
        }
      }
    }
    .downloadAppToday {
      @media screen and (max-width: 991px) {
        max-width: 60%;
        margin: 0 auto;
      }
    }
  }
  &::after,
  &:before {
    content: "";
    display: inline-block;
    position: absolute;
    background-repeat: no-repeat;
    z-index: -1;
  }
  &::after {
    background-image: url("../" + $bg-base-path + "/app_home_vector.png");
    bottom: 50px;
    width: 200px;
    height: 150px;
    right: -15px;
  }
  &:before {
    background-image: url("../" + $bg-base-path + "/assignmentbottomline.svg");
    top: 50px;
    width: 200px;
    height: 150px;
    left: 0;
  }
  .getstarted {
    span {
      &:first-child {
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        color: $theme-color;
        display: block;
        margin-bottom: 6px;
        @media screen and (max-width: 767px) {
          font-size: 24px;
          line-height: 32px;
        }
      }
      &:last-child {
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        color: $black-color;
      }
    }
    padding: 0 20px;
    &:not(:last-child) {
      border-right: 1px solid $theme-color;
    }
  }
}
.benefitsDevice {
  position: relative;
  overflow: hidden;
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    background-repeat: no-repeat;
    z-index: -1;
    background-image: url("../" + $bg-base-path + "/dots-3.svg");
    bottom: 50px;
    width: 190px;
    height: 90px;
    right: -15px;
  }
  .benefits_section {
    max-width: 70%;
    margin: 0 auto 20px;
  }
  .benefits {
    li {
      display: flex;
      align-items: center;
      margin-top: 25px;
      span {
        margin-left: 35px;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        color: $black-color;
        max-width: 60%;
        @media screen and (max-width: 767px) {
          margin-left: 20px;
          font-size: 16px;
          line-height: 24px;
          max-width: 100%;
        }
      }
    }
  }
}
.interface {
  .slickSlider {
    .slickSlide {
      text-align: center;
    }
  }
}
.testimonial {
  [class*="prev"] {
    left: -50px;
    @media screen and (max-width: 991px) {
      display: none !important;
    }
  }
  [class*="next"] {
    right: -50px;
    @media screen and (max-width: 991px) {
      display: none !important;
    }
  }
  .slickSlide {
    padding: 0 16px 80px;
    @media screen and (max-width: 991px) {
      padding-bottom: 40px;
    }
    .feedbackDetail {
      background: $white-color;
      border: 1px solid #eff0f7;
      -webkit-box-shadow: 0px 6px 30px rgba(17, 30, 39, 0.1);
      box-shadow: 0px 6px 30px rgba(17, 30, 39, 0.1);
      border-radius: 10px;
      padding: 40px 20px;
      .reviewTitle {
        margin-top: 24px;
        margin-bottom: 12px;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: $theme-color;
      }
      .customerDetail {
        .name {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: $theme-color;
        }
        .id {
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #6f6c90;
        }
      }
      .rating {
        height: 20px;
        span {
          span {
            margin-right: 8px;
          }
        }
      }
    }
  }
}
.downloadApp {
  background-image: url("../" + $bg-base-path + "/downloadAppbg.png");
  padding: 40px 0 50px;
  margin-top: 100px;
  @media screen and (max-width: 991px) {
    margin-top: 0;
  }
  .downloadAppBg {
    position: absolute;
    top: -107px;
    @media screen and (max-width: 991px) {
      display: none;
    }
  }
}
